import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { PricingList } from "@warrenio/api-spec/spec.oats.gen";
import { atom } from "jotai/vanilla";
import { siteCurrencyAtom } from "../../config.ts";
import { queryThen } from "../../utils/query/mergeQueries.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import type { ApiClient } from "../api/apiClient";
import { getResponseData } from "../api/apiClient";
import { listToObjectPrices, type ResourcePrices } from "./resourcePricing.ts";

export const queryKey: QueryKey = ["pricing_list"];

export type RawResponse = PricingList;
export type Response = ResourcePrices;

export function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<RawResponse> => {
            return getResponseData(await client.GET("/pricing/list/current", { signal }));
        },
    });
}

export const rawPricesAtom = atomFromStandardQueryOptions(getQuery);

export const pricesAtom = atom((get) =>
    queryThen(get(rawPricesAtom), (list) => listToObjectPrices(list, get(siteCurrencyAtom))),
);
