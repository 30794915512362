import type { ErrorComponentProps } from "@tanstack/react-router";
import { useEffect } from "react";
import { ErrorFallback } from "./ErrorFallback.tsx";
import { notifyError } from "./errorReporting.tsx";

export function RouterErrorFallback({ error, info }: ErrorComponentProps) {
    // Since the router swallows errors, we need to report them here
    // TODO: Check https://github.com/TanStack/router/issues/1513#issuecomment-2473003905
    useEffect(() => {
        if (error instanceof Error) {
            notifyError(error, { tags: ["route_error"], context: info });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return <ErrorFallback error={error} />;
}
