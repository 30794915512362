import type { RegisteredRouter } from "@tanstack/react-router";
import { useEffect } from "react";
import { last } from "remeda";
import { isAdminPath } from "../../admin/adminMode.ts";
import { useAnalytics } from "./analytics.ts";

/**
 * Remove `$param` placeholders from a route path.
 *
 * "/compute/vm/$location/$vmId/" -> "/compute/vm/__/__/"
 */
function sanitizeRoutePath(path: string) {
    return path.replace(/\$\w+/g, "__");
}

function getSanitizedPath(router: RegisteredRouter) {
    const match = last(router.state.matches)!;
    return sanitizeRoutePath(match.fullPath as string);
}

/** Send a page view event on each page load of the {@link router} */
export function useRouterAnalytics(router: RegisteredRouter) {
    const analytics = useAnalytics();

    // console.debug("initial state: %o", router.state);

    useEffect(() => {
        function sendPageViewEvent() {
            const path = getSanitizedPath(router);
            // Filter admin paths, for now (should we track these?)
            if (isAdminPath(path)) {
                return;
            }

            // TODO: Add referrer
            analytics.event({ event: "page_view", page_location: path });
        }

        // Send initial page view event
        sendPageViewEvent();

        // Send events on each page load (note that the first load does not currently seem to trigger "onLoad")
        return router.subscribe("onLoad", (_location) => {
            sendPageViewEvent();
        });
    }, [analytics, router]);
}
