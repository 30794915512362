import NiceModal from "@ebay/nice-modal-react";
import { Outlet } from "@tanstack/react-router";
import { useAtomValue } from "jotai/react";
import { type ComponentType, type ReactNode, Suspense, useEffect } from "react";
import { Fragment } from "react/jsx-runtime";
import { useIsInAdminMode } from "../../admin/adminMode.ts";
import { DoNotSuspendHere } from "../../components/dev/DoNotSuspendHere.tsx";
import { MainLayoutContentLoadingSuspense } from "../../components/loading/Loading.tsx";
import { RemountOnPageUuid } from "../../utils/router/RemountOnPageUuid.tsx";
import { currentApiKeyAtom } from "../api/apikey.store.ts";
import { Toaster } from "../notifications/Toaster.tsx";
import { useLoadChatProvider } from "../support/WChatProvider.tsx";

/** Root component inside the router. Contains various providers & utility wrappers. */
export function BaseRouterRoot({
    Layout,
}: {
    /** Component to wrap the actual page content in. */
    Layout: ComponentType<{ children: ReactNode }>;
}) {
    const resetKey = useAtomValue(currentApiKeyAtom);
    // NB: NiceModal must be inside router so modals can access the router context
    return (
        // NB: Re-create ALL components when the API key changes since React Query has a bug where it does not
        // invalidate queries when the query client changes.
        <Fragment key={resetKey}>
            <Suspense fallback={<DoNotSuspendHere />}>
                <NiceModal.Provider>
                    <Layout>
                        <MainLayoutContentLoadingSuspense>
                            <RemountOnPageUuid>
                                <Outlet />
                            </RemountOnPageUuid>
                        </MainLayoutContentLoadingSuspense>
                    </Layout>
                    <Toaster />
                    <Suspense>
                        <WChatLoader />
                    </Suspense>
                </NiceModal.Provider>
            </Suspense>
        </Fragment>
    );
}

function WChatLoader() {
    const isAdmin = useIsInAdminMode();
    useLoadChatProvider(!isAdmin);

    useEffect(() => {
        document.body.classList.toggle("hideChat", isAdmin);
    }, [isAdmin]);

    return null;
}
