import { filterNulls } from "@warrenio/utils/collections/filterNulls";
import { makeOnce } from "@warrenio/utils/makeOnce";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { exhaustiveSwitchCheck } from "@warrenio/utils/unreachable";
import { useAtomValue } from "jotai/react";
import { atom } from "jotai/vanilla";
import { useEffect, useMemo } from "react";
import { loadThemeScripts } from "../../ScriptLoader.tsx";
import { isBuiltRelease } from "../../utils/environment.ts";
import { loadScript } from "../../utils/loadScript.ts";

//#region Types

export interface PageViewEvent {
    event: "page_view";
    page_location: string;
    page_referrer?: string;
    engagement_time_msec?: number; // NB: Only used by GTM
}

export interface GeneralEvent {
    event: "event";
    event_action: string;
    event_category?: string;
    event_label?: string;
    value?: number;
}

export type AnalyticsEvent = PageViewEvent | GeneralEvent;

interface AnalyticsProvider {
    load(): void;
    /** NB: This must work even when the provider has not been loaded yet */
    event(event: AnalyticsEvent): void;
}

//#endregion

//#region gtag.js

type GtagDataLayer = IArguments[];

declare global {
    // eslint-disable-next-line no-var
    var dataLayer: GtagDataLayer;
}

/**XXX: Need to extend the `gtag.js` type definitions since they are incomplete */
interface GtagConfigParamsFix extends Gtag.ConfigParams {
    debug_mode?: boolean;
}

const initGtag = makeOnce((tagId: string, config: GtagConfigParamsFix): AnalyticsProvider => {
    window.dataLayer ||= [];

    window.gtag = function gtag() {
        /* eslint-disable prefer-rest-params */
        // biome-ignore lint/style/noArguments: The SDK requires using the `arguments` object
        window.dataLayer.push(arguments);
        /* eslint-enable prefer-rest-params */
    };

    window.gtag("js", new Date());
    window.gtag("config", tagId, config);

    return {
        load() {
            const scriptUrl = `https://www.googletagmanager.com/gtag/js?id=${tagId}`;
            // Load in the background since we stub out the gtag function
            discardPromise(loadScript(scriptUrl));

            gtagEvent("initialize");
        },
        event({ event, ...params }) {
            gtagEvent(event, params);
        },
    };
});

function gtagEvent(event: string, params?: Record<string, any>) {
    window.gtag("event", event, params);
}

//#endregion

//#region Google Tag Manager

type GtmDataLayer = Record<string, any>[];

function getGtmDataLayer(dataLayerName: string) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return (((window as any)[dataLayerName] as GtmDataLayer) ||= []);
}

interface GtmRawEvent {
    event: string;
    [key: string]: unknown;
}

/** Translate from gtag event format to legacy GTM format (used by old UI) */
function translateGtmEvent(event: AnalyticsEvent): GtmRawEvent {
    const type = event.event;
    switch (type) {
        case "page_view":
            return {
                event: "pageview",
                page: {
                    url: event.page_location,
                },
            };
        case "event":
            return {
                event: "event",
                eventProps: {
                    action: event.event_action,
                    category: event.event_category,
                    label: event.event_label,
                    value: event.value,
                },
            };
        default:
            exhaustiveSwitchCheck(type);
    }
}

// NB: `dataLayerName` should not be "dataLayer" to prevent conflicts with `gtag.js`
const initGtm = makeOnce((containerId: string, dataLayerName: string = "gtmDataLayer"): AnalyticsProvider => {
    const dl = getGtmDataLayer(dataLayerName);
    dl.push({ "gtm.start": new Date().getTime(), event: "gtm.js" });

    return {
        load() {
            const scriptUrl = `https://www.googletagmanager.com/gtm.js?id=${containerId}&l=${dataLayerName}`;
            discardPromise(loadScript(scriptUrl));
        },
        event(event) {
            getGtmDataLayer(dataLayerName).push(translateGtmEvent(event));
        },
    };
});

//#endregion

//#region Provider initialization

const gtagAtom = atom((get) => {
    const gtagId = "G-XGF7GZCCVL";
    return initGtag(gtagId, { debug_mode: !isBuiltRelease, send_page_view: false });
});

const gtmAtom = atom((get) => {
    const gtmId = "GTM-WZSJSQSQ";
    return initGtm(gtmId);
});

const providersAtom = atom<AnalyticsProvider[]>((get) => filterNulls([get(gtagAtom), get(gtmAtom)]));

//#endregion

const analyticsEnabled = false;

//#region Public interface

export class AnalyticsManager {
    constructor(private providers: AnalyticsProvider[]) {}

    event(event: AnalyticsEvent) {
        // logEvent(event);
        for (const provider of this.providers) {
            provider.event(event);
        }
    }
}

function logEvent(event: AnalyticsEvent) {
    const { event: eventType, ...params } = event;
    console.debug("Analytics %s: %o", eventType, params);
}

export function useAnalytics(): AnalyticsManager {
    const providers = useAtomValue(providersAtom);
    return useMemo(() => new AnalyticsManager(providers), [providers]);
}

export function useInitializeAnalytics() {
    const providers = useAtomValue(providersAtom);

    useEffect(() => {
        if (!analyticsEnabled) {
            return;
        }

        // NB: Load analytics after any user scripts (in case they are used for consent)
        discardPromise(
            loadThemeScripts().then(() => {
                console.debug("Initializing analytics providers");
                for (const provider of providers) {
                    provider.load();
                }
            }),
        );
    }, [providers]);
}

//#endregion
