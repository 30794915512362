import { type QueryClient, type QueryKey, queryOptions } from "@tanstack/react-query";
import type {
    VirtualMachine,
    VmAddDiskBody,
    VmBackupEnableDisableBody,
    VmChangePasswordBody,
    VmDeleteDiskBody,
    VmModifyBody,
    VmReplicaCreateBody,
    VmReplicaDeleteBody,
    VmResizeDiskBody,
    VmStartInRescueBody,
} from "@warrenio/api-spec/spec.oats.gen";
import { mapFromEntries, mergeMaps } from "@warrenio/utils/collections/maps";
import { assertNotNull } from "@warrenio/utils/notNull";
import { MINUTES } from "@warrenio/utils/timeUnits";
import { atomFamily } from "jotai/utils";
import { first } from "remeda";
import { link } from "../../components/Action.tsx";
import { jsonEncodedBody, urlEncodedBody } from "../../utils/fetchClient.ts";
import { optimisticPromise } from "../../utils/query/optimisticPromise.ts";
import { produceQueryData } from "../../utils/query/produceQueryData.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import { mutationOptions, runMutation } from "../../utils/query/runMutation.ts";
import { capitalize } from "../../utils/string.ts";
import { type ApiClient, getResponseData } from "../api/apiClient.ts";
import { getResourceIconClass } from "../api/resourceTypes.tsx";
import { atomAllLocationsQuery } from "../location/atomAllLocationsQuery.ts";
import { getQueryKey as getIpQueryKey } from "../network/ipAddress/apiOperations.ts";
import { getQueryKey as getVpcQueryKey } from "../network/vpc/apiOperations.ts";
import { dismissToast, raiseProgressToast, raiseRequestToast, type Toast } from "../notifications/toast.tsx";
import type { VirtualMachineWithAssigned } from "./joinAssignedQuery.ts";
import { vmLink } from "./links.ts";
import type {
    VmCloneMutation,
    VmCreateMutation,
    VmRebuildFromReplicaMutation,
    VmReinstallMutation,
} from "./vm.store.ts";
import { getPrimaryStorage } from "./vmEntityUtils.ts";

/** Virtual machine with extra `location` property */
export interface VirtualMachineLoc extends VirtualMachine {
    location: string;
    $type: "virtual_machine";
}

/** Map of {@link VirtualMachine}s keyed by ID */
export type Response = Map<VirtualMachine["uuid"], VirtualMachineLoc>;

export const emptyResponse: Response = new Map();

export interface Params {
    location: string;
    billing_account_id?: number;
}

export const toastOptions: Partial<Toast> = { icon: getResourceIconClass("virtual_machine") };

export function getQueryKey(params?: Params): QueryKey {
    return params == null ? ["vm/list"] : ["vm/list", params.location, params.billing_account_id];
}

function vmFromData(data: VirtualMachine, location: Params["location"]): VirtualMachineLoc {
    return { ...data, $type: "virtual_machine", location };
}

export function getSingleQuery(client: ApiClient, params: Params) {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<Response> => {
            const { location, billing_account_id } = params;
            return mapFromEntries(
                getResponseData(
                    await client.GET("/{location}/user-resource/vm/list", {
                        signal,
                        params: { path: { location }, query: { billing_account_id } },
                    }),
                ),
                (vm) => [vm.uuid, vmFromData(vm, location)] as const,
            );
        },
    });
}

export const vmQueryAtom = atomFamily((location: string) => atomFromStandardQueryOptions(getSingleQuery, { location }));

export const allVmQueryAtom = atomAllLocationsQuery(vmQueryAtom, (results) => mergeMaps(results));

export function vmCreateMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["virtual_machine/create"],
        async mutationFn({ body, location }: VmCreateMutation) {
            return vmFromData(
                getResponseData(
                    await api.POST("/{location}/user-resource/vm", {
                        ...urlEncodedBody,
                        params: { path: { location } },
                        body,
                    }),
                ),
                location,
            );
        },
        onMutate({ requestUuid, body, location, previousRequestUuid }) {
            if (previousRequestUuid) {
                // Dismiss previous (error) toast if it exists
                dismissToast(previousRequestUuid);
            }

            raiseVmProgressToast(requestUuid, `Creating VM [${body.name}]`);
            return { queryKey: getQueryKey({ location }) };
        },
        onSuccess(response, _mutation, { queryKey }) {
            produceQueryData(queryClient, queryKey, emptyResponse, (draft) => {
                console.info("Optimistic VM create: uuid: %s", response.uuid);
                draft.set(response.uuid, response);
            });
        },
        async onSettled(res, err, mutation, context) {
            const {
                requestUuid,
                location,
                body: { reserve_public_ip, network_uuid },
            } = mutation;
            dismissToast(requestUuid);

            raiseRequestToast(err, {
                ...toastOptions,

                success: "VM created",
                successType: "success",
                viewLink: res ? vmLink(res) : undefined,

                error: "Error creating VM",
                retryLink: link({
                    // NB: Can not use `ResourceCreateLinks.virtual_machine` here due to Tanstack Router parameter strong typing
                    to: "/compute/create",
                    state: { vmCreateMutation: mutation },
                }),
            });

            assertNotNull(context);
            const { queryKey } = context;
            optimisticPromise(queryClient.invalidateQueries({ queryKey }));

            if (reserve_public_ip) {
                await queryClient.invalidateQueries({ queryKey: getIpQueryKey({ location }) });
            }

            if (!network_uuid) {
                await queryClient.invalidateQueries({ queryKey: getVpcQueryKey({ location }) });
            }

            console.info("Finish VM create: uuid: %s", res?.uuid);
        },
    });
}

export function modifyVmMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["virtual_machine/update"],
        async mutationFn({ body, location }: { body: VmModifyBody; location: string }) {
            const result = getResponseData(
                await api.PATCH("/{location}/user-resource/vm", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { location } },
                }),
            );
            return vmFromData(result, location);
        },
        async onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Virtual Machine updated",
                error: "Error updating Virtual Machine",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
    });
}

interface VmResizeBody {
    vm: VmModifyBody;
    disk: VmResizeDiskBody | undefined;
}

/** Composite mutation for resizing VM and disk */
export function resizeVmMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationFn: async ({
            vm,
            body,
            location,
        }: {
            vm: VirtualMachineWithAssigned;
            body: VmResizeBody;
            location: string;
        }) => {
            const {
                vm: { vcpu, ram },
                disk,
            } = body;

            // call modifyVmMutation and resizeDiskMutation only when the corresponding values have changed
            const vmResult =
                vcpu !== vm.vcpu || ram !== vm.memory
                    ? await runMutation(queryClient, modifyVmMutation(api, queryClient), {
                          body: { ...body.vm, vcpu, ram },
                          location,
                      })
                    : vm;

            const primary = getPrimaryStorage(vm);
            const diskResult =
                primary != null && disk != null && disk.size_gb !== primary.size
                    ? await runMutation(queryClient, resizeDiskMutation(api, queryClient), {
                          body: disk,
                          location,
                      })
                    : first(vm.storage);
            return { vmResult, diskResult };
        },
    });
}

export function changeVmUserPasswordMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["virtual_machine/change_user_pass"],
        async mutationFn({ body, location }: { body: VmChangePasswordBody; location: string }) {
            return getResponseData(
                await api.PATCH("/{location}/user-resource/vm/user", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { location } },
                }),
            );
        },
        async onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Virtual Machine's user password updated",
                error: "Error updating Virtual Machine's user password",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
    });
}

export function startVmInRescueMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["virtual_machine/start_in_rescue"],
        async mutationFn({ body, location }: { body: VmStartInRescueBody; location: string }) {
            const result = getResponseData(
                await api.POST("/{location}/user-resource/vm/rescue_start", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { location } },
                }),
            );
            return vmFromData(result, location);
        },
        async onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Virtual Machine started in rescue mode",
                error: "Error starting Virtual Machine in rescue mode",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
    });
}

export function changeVmBillingAccountMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["virtual_machine/change_billing_account"],
        async mutationFn({
            uuid,
            location,
            billing_account_id,
        }: {
            uuid: string;
            location: string;
            billing_account_id: number;
        }) {
            const result = getResponseData(
                await api.POST("/{location}/user-resource/resource_billing", {
                    ...jsonEncodedBody,
                    params: { path: { location } },
                    body: { uuid, billing_account_id, resource_type: "vm" },
                }),
            );
            return vmFromData(result, location);
        },
        onSuccess(response, { location }) {
            produceQueryData(queryClient, getQueryKey({ location }), emptyResponse, (draft) => {
                draft.set(response.uuid, response);
            });
        },
        onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Virtual machine's billing account changed",
                error: "Failed to change Virtual Machine's billing account",
            });
            optimisticPromise(queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) }));
        },
    });
}

export function vmCloneMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["virtual_machine/clone"],
        async mutationFn({ body, location }: VmCloneMutation) {
            return vmFromData(
                getResponseData(
                    await api.POST("/{location}/user-resource/vm/clone", {
                        ...jsonEncodedBody,
                        params: { path: { location } },
                        body,
                    }),
                ),
                location,
            );
        },
        onMutate({ body, location, requestUuid }) {
            raiseVmProgressToast(requestUuid, `Cloning VM [${body.name}]`);

            return { queryKey: getQueryKey({ location }) };
        },
        onSuccess(response, _mutation, { queryKey }) {
            produceQueryData(queryClient, queryKey, emptyResponse, (draft) => {
                draft.set(response.uuid, response);
            });
        },
        async onSettled(_res, err, { location, requestUuid, body }, context) {
            dismissToast(requestUuid);

            raiseRequestToast(err, {
                ...toastOptions,
                success: "VM cloned",
                error: "Error cloning VM",
                retryLink: link({
                    to: "/compute/vm/$location/$vmId",
                    params: { location, vmId: body.uuid },
                }),
            });

            assertNotNull(context);
            const { queryKey } = context;
            optimisticPromise(queryClient.invalidateQueries({ queryKey }));

            await queryClient.invalidateQueries({ queryKey: getIpQueryKey({ location }) });
        },
    });
}

export function reinstallVmMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["virtual_machine/reinstall"],
        async mutationFn({ body, location }: VmReinstallMutation) {
            const result = getResponseData(
                await api.POST("/{location}/user-resource/vm/reinstall", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { location } },
                }),
            );
            return vmFromData(result, location);
        },
        onMutate({ body, requestUuid }) {
            raiseVmProgressToast(requestUuid, `Reinstalling VM [${body.uuid}]`);
        },
        async onSettled(_res, err, { location, requestUuid, body }) {
            dismissToast(requestUuid);
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Virtual Machine reinstalled",
                error: "Error reinstalling Virtual Machine",
                retryLink: link({
                    to: "/compute/vm/$location/$vmId",
                    params: { location, vmId: body.uuid },
                }),
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
    });
}

export function startVmMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["virtual_machine/start"],
        async mutationFn({ uuid, location }: { uuid: string; location: string }) {
            const result = getResponseData(
                await api.POST("/{location}/user-resource/vm/start", {
                    ...jsonEncodedBody,
                    body: { uuid },
                    params: { path: { location } },
                }),
            );
            return vmFromData(result, location);
        },
        onSuccess(response, { location }) {
            produceQueryData(queryClient, getQueryKey({ location }), emptyResponse, (draft) => {
                draft.set(response.uuid, response);
            });
        },
        onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Virtual Machine started",
                error: "Error starting Virtual Machine",
            });
            optimisticPromise(queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) }));
        },
    });
}

export function stopVmMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["virtual_machine/stop"],
        async mutationFn({ uuid, location, force }: { uuid: string; location: string; force: boolean }) {
            const result = getResponseData(
                await api.POST("/{location}/user-resource/vm/stop", {
                    ...jsonEncodedBody,
                    body: { uuid, force },
                    params: { path: { location } },
                }),
            );
            return vmFromData(result, location);
        },
        onSuccess(response, { location }) {
            produceQueryData(queryClient, getQueryKey({ location }), emptyResponse, (draft) => {
                draft.set(response.uuid, response);
            });
        },
        onSettled(_res, err, { location, force }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: force ? "Virtual Machine forcefully stopped" : "Virtual Machine stopped",
                error: force ? "Error forcefully stopping Virtual Machine" : "Error stopping Virtual Machine",
            });
            optimisticPromise(queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) }));
        },
    });
}

export function deleteVmMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["virtual_machine/delete"],
        async mutationFn({ uuid, location }: { uuid: string; location: string }) {
            return getResponseData(
                await api.DELETE("/{location}/user-resource/vm", {
                    ...jsonEncodedBody,
                    body: { uuid },
                    params: { path: { location } },
                }),
            );
        },
        async onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Virtual Machine deleted",
                error: "Error deleting Virtual Machine",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
            await queryClient.invalidateQueries({ queryKey: getIpQueryKey({ location }) });
        },
    });
}

//#region VM Disks

export function addDiskMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["virtual_machine/add_disk"],
        async mutationFn({ location, body }: { location: string; body: VmAddDiskBody }) {
            return getResponseData(
                await api.POST("/{location}/user-resource/vm/storage", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { location } },
                }),
            );
        },
        async onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Disk added to Virtual Machine",
                error: "Error adding disk to Virtual Machine",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
    });
}

export function resizeDiskMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["virtual_machine/resize_disk"],
        async mutationFn({ location, body }: { location: string; body: VmResizeDiskBody }) {
            return getResponseData(
                await api.PATCH("/{location}/user-resource/vm/storage", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { location } },
                }),
            );
        },
        async onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Disk resized",
                error: "Error resizing disk",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
    });
}

export function deleteDiskMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["virtual_machine/delete_disk"],
        async mutationFn({ location, body }: { location: string; body: VmDeleteDiskBody }) {
            return getResponseData(
                await api.DELETE("/{location}/user-resource/vm/storage", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { location } },
                }),
            );
        },
        async onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Disk deleted",
                error: "Error deleting disk",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
    });
}

//endregion VM Disks

//region VM Replicas
export function createSnapshotMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["virtual_machine/create_snapshot"],
        async mutationFn({ location, body }: { location: string; body: VmReplicaCreateBody }) {
            return getResponseData(
                await api.POST("/{location}/user-resource/vm/replica", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { location } },
                }),
            );
        },
        async onSettled(_res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Snapshot created",
                error: "Error creating snapshot",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
    });
}

// Delete snapshots or backups by ID
export function deleteReplicaMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["virtual_machine/delete_snapshot"],
        async mutationFn({ location, body }: { location: string; body: VmReplicaDeleteBody }) {
            return getResponseData(
                await api.DELETE("/{location}/user-resource/vm/replica", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { location } },
                }),
            );
        },
        async onSettled(_res, err, { location, body: { type } }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: `${capitalize(type)} deleted`,
                error: `Error deleting ${type}`,
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
    });
}

// Rebuild VM from snapshot or backup
export function rebuildFromReplicaMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["virtual_machine/restore_from_snapshot"],
        async mutationFn({ location, body }: VmRebuildFromReplicaMutation) {
            return getResponseData(
                await api.POST("/{location}/user-resource/vm/rebuild", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { location } },
                }),
            );
        },
        onMutate({ body, requestUuid }) {
            raiseVmProgressToast(requestUuid, `Restoring VM from ${body.type}`);
        },
        async onSettled(_res, err, { location, body: { type, uuid }, requestUuid }) {
            dismissToast(requestUuid);
            raiseRequestToast(err, {
                ...toastOptions,
                success: `Restored VM from ${type}`,
                error: `Error restoring VM from ${type}`,
                retryLink: link({
                    to: "/compute/vm/$location/$vmId",
                    params: { location, vmId: uuid },
                }),
            });

            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
    });
}

// Enable or disable VM backups
export function enableDisableBackupMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["virtual_machine/enable_disable_backup"],
        async mutationFn({ location, body }: { location: string; body: VmBackupEnableDisableBody }) {
            return getResponseData(
                await api.POST("/{location}/user-resource/vm/backup", {
                    ...jsonEncodedBody,
                    body,
                    params: { path: { location } },
                }),
            );
        },
        async onSettled(res, err, { location }) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: `VM Backups ${res?.backup ? "enabled" : "disabled"}`,
                error: "Error enabling/disabling backup",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey({ location }) });
        },
    });
}

//endregion VM Replicas

//#region toasts

export const VM_CREATE_TIMER = 3 * MINUTES;

function raiseVmProgressToast(requestUuid: string, message: string) {
    raiseProgressToast({
        ...toastOptions,
        label: message,
        lifeTime: VM_CREATE_TIMER,
        toastId: requestUuid,
    });
}

//endregion toasts
