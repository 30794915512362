/**
 * Takes the first `n` elements from an iterable.
 */
export function take<T>(iter: Iterable<T>, n: number): T[] {
    const result = [];
    let i = 0;
    for (const item of iter) {
        if (i++ >= n) {
            break;
        }
        result.push(item);
    }
    return result;
}
