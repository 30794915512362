import type { ReplicaType, VirtualMachineStorageReplica } from "@warrenio/api-spec/spec.oats.gen";
import type { VirtualMachineLoc } from "./vmQuery.ts";

/**
 * NB: The primary storage might not exist (for broken VMs and for VMs which have had their boot disk removed)
 */
export function getPrimaryStorage<T extends { primary: boolean }>(vm: { storage: T[] }): T | undefined {
    return vm.storage.find((item) => item.primary);
}

export function getReplicasByType(vm: VirtualMachineLoc, type: ReplicaType) {
    return getPrimaryStorage(vm)?.replica?.filter((item: VirtualMachineStorageReplica) => item.type === type) ?? [];
}

export function getReplicaByUuid(vm: VirtualMachineLoc, replicaUuid: string) {
    return getPrimaryStorage(vm)?.replica?.find((item: VirtualMachineStorageReplica) => item.uuid === replicaUuid);
}
