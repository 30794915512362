import { Children, Suspense, type ReactNode, type SuspenseProps } from "react";
import { InfoTooltip } from "../../components/WTooltip.tsx";
import { isStorybook } from "../../utils/environment.ts";
import { ReportingErrorBoundary } from "./ReportingErrorBoundary.tsx";
import { errorToString } from "./errorToString.tsx";

interface IsolatedProps {
    fallback?: SuspenseProps["fallback"];
}

const IsolatedError = ({ error }: { error: unknown }) => (
    <InfoTooltip
        text={errorToString(error)}
        // Open error description automatically on Storybook for debugging
        isOpen={isStorybook}
    >
        <span className="text-error">Error</span>
    </InfoTooltip>
);

/** Wrapper component to isolate components from each other - contains an error boundary and suspense */
export function Isolated({ children, fallback }: { children: ReactNode } & IsolatedProps) {
    return (
        <ReportingErrorBoundary FallbackComponent={IsolatedError} tags="Isolated">
            <Suspense fallback={fallback}>{children}</Suspense>
        </ReportingErrorBoundary>
    );
}

export function IsolatedChildren({ children, ...props }: { children: ReactNode[] } & IsolatedProps) {
    return Children.map(children, (child) => <Isolated {...props}>{child}</Isolated>);
}
