import { useRef } from "react";
import { useLayoutSizeObserver } from "../../utils/useLayoutSizeObserver.ts";

const debugPerformance = false;

const breakPoints: [number, string][] = [
    // [1640, "xl"],
    // [1280, "lg"],
    [1024, "md"],
    [768, "sm"],
    [520, "xs"],
    [0, "xxs"],
];
const defaultBreakpoint = "md";

function getBreakPoint(width: number) {
    const breakpoint = breakPoints.find(([bw]) => width >= bw);
    const size = breakpoint?.[1] ?? defaultBreakpoint;
    return size;
}

/** Add a `data-screen` attribute to with the current breakpoint size to an element.
 * @returns A ref that should be attached to the element to observe the size of.
 */
export function useBreakpointSizeAttribute() {
    const ref = useRef<HTMLElement>(null);
    useLayoutSizeObserver({
        ref,
        onResize(rect, el) {
            const { width } = rect;
            // Mutate the DOM directly so the attribute is available immediately on first paint
            updateBreakpointAttribute(width, el);
        },
    });
    return ref;
}

export function updateBreakpointAttribute(width: number, el: HTMLElement) {
    const size = getBreakPoint(width);

    if (el.getAttribute("data-screen") !== size) {
        if (import.meta.env.DEV && debugPerformance) {
            console.debug("Set breakpoint: %s (%o) on %o", size, width);

            performance.mark(`Set breakpoint: ${size}`);
        }

        el.setAttribute("data-screen", size);
    }
}

export function getBreakPointAttribute(width: number) {
    return { "data-screen": getBreakPoint(width) };
}
