import type { IpAddressResourceType } from "@warrenio/api-spec/spec.oats.gen";
import { unreachableSwitchCase } from "@warrenio/utils/unreachable";
import type { ResourceType } from "../../api/resourceTypes.tsx";
import type { VirtualMachineLoc } from "../../compute/vmQuery.ts";
import { getResourceName, type AnyResource } from "../../resource/getResourceName.tsx";
import type { ManagedServiceLoc } from "../../services/servicesQuery.ts";
import type { ResourceId } from "../genericResources.ts";
import type { LoadBalancerWithType } from "../loadbalancer/apiOperations.ts";
import type { IpAddressWithType } from "./apiOperations.ts";

export type IpAssignableResource = VirtualMachineLoc | LoadBalancerWithType | ManagedServiceLoc;

export function getAddressFromResource(resource: IpAssignableResource | null): string | undefined {
    if (resource !== null) {
        switch (resource.$type) {
            case "managed_service":
                return "service_ip" in resource.properties ? resource.properties.service_ip : undefined;
            case "virtual_machine":
                return resource.private_ipv4;
            case "load_balancer":
                return resource.private_address;
        }
    }
    return undefined;
}

/** An ID for a resource that can be assigned with an IP address */
export interface IpResourceId extends ResourceId<IpAddressResourceType> {
    $type: IpAddressResourceType;
    $id: string;
    location: string;
}

export function getResourceId(item: IpAssignableResource): IpResourceId {
    return {
        $type: item.$type === "managed_service" ? "service" : item.$type,
        $id: item.uuid,
        location: item.location,
    };
}

export const UNASSIGNED_RESOURCE = null;
export type UNASSIGNED_RESOURCE = typeof UNASSIGNED_RESOURCE;

export const UNASSIGNED_KEY = "__unassigned__";

export function getResourceStringId(item: IpAssignableResource | UNASSIGNED_RESOURCE): string {
    if (item === UNASSIGNED_RESOURCE) {
        return UNASSIGNED_KEY;
    }

    return resourceIdToString(getResourceId(item));
}

export function resourceIdToString(id: IpResourceId | UNASSIGNED_RESOURCE): string {
    if (id === UNASSIGNED_RESOURCE) {
        return UNASSIGNED_KEY;
    }

    return JSON.stringify(id);
}

export function getDisplayName(item: AnyResource | UNASSIGNED_RESOURCE): string {
    if (item === UNASSIGNED_RESOURCE) {
        return "Unassigned";
    }

    return getResourceName(item);
}

export function getAssignedResourceId({
    assigned_to,
    assigned_to_resource_type,
    location,
}: IpAddressWithType): IpResourceId | UNASSIGNED_RESOURCE {
    if (assigned_to == null || assigned_to_resource_type == null) {
        return UNASSIGNED_RESOURCE;
    }
    return {
        $type: assigned_to_resource_type,
        $id: assigned_to,
        location,
    };
}

export function ipAddressResourceTypeToType(type: IpAddressResourceType) {
    switch (type) {
        case "load_balancer":
            return "load_balancer" satisfies ResourceType;
        case "service":
            return "managed_service" satisfies ResourceType;
        case "virtual_machine":
            return "virtual_machine" satisfies ResourceType;
        default:
            unreachableSwitchCase(type);
    }
}
