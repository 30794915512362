import type { VirtualMachine } from "@warrenio/api-spec/spec.oats.gen";
import { mapFrom, mapOnValues, mergeMaps } from "@warrenio/utils/collections/maps";
import { atomFamily } from "jotai/utils";
import { atom } from "jotai/vanilla";
import { mergeLoadedQueries, type QMergedResult } from "../../utils/query/mergeQueries.ts";
import { atomAllLocationsQuery } from "../location/atomAllLocationsQuery.ts";
import { ipQueryAtom, type IpAddressWithType } from "../network/ipAddress/apiOperations.ts";
import { UNASSIGNED_RESOURCE } from "../network/ipAddress/resourceId.ts";
import { pricesAtom } from "../pricing/query.ts";
import { VmPriceCalculator, type Price } from "../pricing/resourcePricing.ts";
import { vmQueryAtom, type VirtualMachineLoc } from "./vmQuery.ts";

export interface VirtualMachineWithAssigned extends VirtualMachineLoc {
    price: Price;
    priceCalc: VmPriceCalculator;
    assignedPublicIp: IpAddressWithType | UNASSIGNED_RESOURCE;
}

export type AssignedVmResponse = Map<VirtualMachine["uuid"], VirtualMachineWithAssigned>;

export const assignedVmsQueryAtom = atomFamily((location: string) => {
    const vmQuery = vmQueryAtom(location);
    const ipQuery = ipQueryAtom(location);

    return atom((get): QMergedResult<AssignedVmResponse | undefined> => {
        const results = {
            vms: get(vmQuery),
            ips: get(ipQuery),
            prices: get(pricesAtom),
        };
        return mergeLoadedQueries(results, ({ vms, ips, prices }) => {
            const ipsByAssignedTo = mapFrom(ips.values(), (ip) => ip.assigned_to);

            return mapOnValues(vms, (vm): VirtualMachineWithAssigned => {
                const { uuid } = vm;
                const assignedPublicIp = ipsByAssignedTo?.get(uuid) ?? UNASSIGNED_RESOURCE;
                const priceCalc = VmPriceCalculator.fromPrices(prices, vm);
                const vmCalc = priceCalc.calculatePrice(!!assignedPublicIp);
                // console.debug(vm.name, vmCalc, vm);
                return {
                    ...vm,
                    price: vmCalc.total,
                    priceCalc,
                    assignedPublicIp,
                };
            });
        });
    });
});

export const allAssignedVmsQueryAtom = atomAllLocationsQuery(assignedVmsQueryAtom, (results) => mergeMaps(results));
