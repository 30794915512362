import { NotFoundError } from "@warrenio/utils/collections/getAndAssert";
import { take } from "@warrenio/utils/collections/take";
import { isArray } from "remeda";
import { registerErrorField } from "../error/errorFields.tsx";
import { getResourceTypeName, type ResourceType } from "./resourceTypes.tsx";

export class ResourceNotFoundError extends NotFoundError {
    name = "ResourceNotFoundError";
    constructor(
        public readonly type: ResourceType,
        id: ConstructorParameters<typeof NotFoundError>[1],
        /** For debugging */
        public availableIds: unknown[] = [],
    ) {
        super(getResourceTypeName(type), id);
    }
}

registerErrorField("availableIds", isArray);

// XXX: Not suppressed since there seem to be some bugs still which cause this to be thrown
// suppressErrorClass(ResourceNotFoundError);

export function getResourceById<T, TId>(resources: Map<TId, T>, id: TId, type: ResourceType): T {
    const resource = resources.get(id);
    if (!resource) {
        throw new ResourceNotFoundError(type, id, take(resources.keys(), 30));
    }
    return resource;
}
