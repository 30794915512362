import { useAtomValue } from "jotai/react";
import { Link } from "react-aria-components";
import { ClipBoardTooltip } from "../../components/ClipBoardTooltip.tsx";
import { HeroBlock } from "../../components/HeroBlock.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { ApiDocLink } from "../../modules/access/token/ApiDocLink.tsx";
import { getApiUrl } from "../../modules/api/createApiClient.ts";
import R from "../../modules/billing/referral/ReferralProgram.module.css";
import { graphQlUriAtom } from "../graphql/graphQlUriAtom.tsx";

// TODO: Proper URI building for Grafana
function getGrafanaUri() {
    const { hostname, protocol } = window.location;
    return `${protocol}//grafana.${hostname}`;
}

function getSupportUri() {
    const { hostname } = window.location;
    return `https://warrenio.atlassian.net/servicedesk/customer/portal/2/group/2/create/12?customfield_10533=https://${hostname}`;
}

export function HelpView() {
    const graphqlUri = useAtomValue(graphQlUriAtom);

    return (
        <HeroBlock title="Help" icon="jp-help-icon">
            <div>
                <p className="text-muted font-size-default pb-3">
                    This page provides essential guidance and support for administrators managing the platform. Whether
                    you’re setting up user roles, configuring system settings, or troubleshooting common issues, you’ll
                    find instructions and best practices to streamline your workflow. Explore the topics below to get
                    the most out of your admin tools and ensure a smooth experience for your team. If you need further
                    assistance, our support team is always here to help.
                </p>

                <div className={R.Share}>
                    <div>
                        <div className="color-muted font-size-default pb-1">API Url</div>
                        <div className={R.dummyField}>
                            <ClipBoardTooltip>{getApiUrl("")}</ClipBoardTooltip>
                        </div>
                    </div>

                    <div>
                        <div className="color-muted font-size-default pb-1">GraphQL Url</div>
                        <div className={R.dummyField}>
                            <ClipBoardTooltip>{graphqlUri}</ClipBoardTooltip>
                        </div>
                    </div>
                </div>
            </div>

            <div className="font-size-default pt-4">
                <div className="color-muted text-uppercase">URLs to know</div>

                <div className="pt-2">
                    <ApiDocLink>
                        <MaskIcon className="jp-icon-text-editor color-primary size-0.75rem" /> API Documentation
                    </ApiDocLink>
                </div>

                <div className="pt-1">
                    <Link href="https://documenter.getpostman.com/view/2599901/RznLEagg" target="_blank">
                        <MaskIcon className="jp-icon-text-editor color-primary size-0.75rem" /> Admin API documentation
                    </Link>
                </div>

                <div className="pt-1">
                    <Link
                        href="https://warrenio.atlassian.net/wiki/spaces/WARP/pages/53215252/Feature+docs+and+admin+guides"
                        target="_blank"
                    >
                        <MaskIcon className="jp-icon-text-editor color-primary size-0.75rem" /> Feature docs and admin
                        guides
                    </Link>
                </div>

                <div className="pt-1">
                    <Link
                        href="https://warrenio.atlassian.net/wiki/spaces/WARP/pages/42237961/Release+Notes"
                        target="_blank"
                    >
                        <MaskIcon className="jp-icon-text-editor color-primary size-0.75rem" /> Release Notes
                    </Link>
                </div>

                <div className="pt-1">
                    <Link href={getSupportUri()} target="_blank">
                        <MaskIcon className="jp-icon-text-editor color-primary size-0.75rem" /> Support tickets
                    </Link>
                </div>

                <div className="pt-1">
                    <Link href={`https://studio.apollographql.com/sandbox?endpoint=${graphqlUri}`} target="_blank">
                        <MaskIcon className="jp-icon-text-editor color-primary size-0.75rem" /> GraphQL Apollo Studio
                    </Link>
                </div>

                <div className="pt-1">
                    <Link href={getGrafanaUri()} target="_blank">
                        <MaskIcon className="jp-icon-text-editor color-primary size-0.75rem" /> Grafana
                    </Link>
                </div>
            </div>
        </HeroBlock>
    );
}
