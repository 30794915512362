import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "@tanstack/react-router";
import { makeOnce } from "@warrenio/utils/makeOnce";
import { useAtomValue } from "jotai/react";
import { useHydrateAtoms } from "jotai/utils";
import { memo } from "react";
import { I18nProvider } from "react-aria";
import { siteLocaleAtom } from "../../config.ts";
import { useQueryHotReload } from "../../mock-api/useQueryHotReload.ts";
import { routerAtom } from "../../router.store.ts";
import { createStandardRouter } from "../../router.ts";
import { isBuiltRelease } from "../../utils/environment.ts";
import { useInitializeAnalytics } from "../analytics/analytics.ts";
import { useRouterAnalytics } from "../analytics/useRouterAnalytics.ts";
import { queryClientAtom } from "../api/queryClient";
import QueryPrefetch from "./QueryPrefetch.tsx";

const getRouter = makeOnce(createStandardRouter);

/** Root component in production */
const MainRoot = memo(function MainRoot() {
    const siteLocale = useAtomValue(siteLocaleAtom);
    const queryClient = useAtomValue(queryClientAtom);

    useHydrateAtoms([[routerAtom, getRouter()]]);

    useQueryHotReload(queryClient);

    useInitializeAnalytics();
    useRouterAnalytics(getRouter());

    return (
        <QueryClientProvider client={queryClient}>
            <I18nProvider locale={siteLocale}>
                {
                    // Only prefetch in release (to prevent console spam in development)
                    // NB: This should be imported using `React.lazy` since otherwise it can cause cyclic dependencies, but MainRoot is already lazy-loaded
                    isBuiltRelease && <QueryPrefetch />
                }
                <RouterProvider router={getRouter()} />
            </I18nProvider>
        </QueryClientProvider>
    );
});

export default MainRoot;
